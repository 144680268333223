.BackgroundVideo_Container__2Arbr {
  overflow: hidden;
}

/* Style the video: 100% width and height to cover the entire window */
.BackgroundVideo_Video__3S-M5 {
  width: 100%;
  height: 100%;
}

/* Add some content at the bottom of the video/page */
.BackgroundVideo_Content__2yvaV {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.BackgroundVideo_SubContent__20UFa {
  text-align: center;
  padding: 10px;
}
.BackgroundVideo_SubContent__20UFa p {
  color: #eeeeeead;
  font-size: 1.3rem;
}
.BackgroundVideo_SubContent__20UFa button {
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
}
.BackgroundVideo_SubContent__20UFa button:hover {
  background-color: #f1f1f1;
  color: #000000c0;
}

.BackgroundVideo_SubContent__20UFa img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #f1f1f1 1px solid;
}
